import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const News = (item, idx) => {
  const { t } = useTranslation()
  return (
    <div className="item" key={idx}>
      <img className="image" src={item.image.url} alt="" />
      <h3>{item.title}</h3>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: item.description.html }}
      ></div>
      <a className="link" href={item.link.url}>
        {t("read_more")}
      </a>
    </div>
  )
}

const NewInfo = ({ id, primary, items }) => {
  return (
    <div className="new-info container-small slice" id={id}>
      <h2>{primary.heading}</h2>
      {items.map(News)}
    </div>
  )
}

NewInfo.propTypes = {
  id: PropTypes.string.isRequired,
  primary: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default NewInfo
