import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

const SEO = ({ title, description, image }) => {
  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
