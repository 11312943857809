import React from "react"
import PropTypes from "prop-types"

import About from "./About"
import FAQ from "./FAQ"
import Hero from "./Hero"
import Locations from "./Locations"
import NewInfo from "./NewInfo"
import Permission from "./Permission"
import Stats from "./Stats"
import Tarja from "./Tarja"

const SliceRenderer = props => {
  const sliceRender = slice => {
    const id = props.page + "-" + slice.slice_type
    const componentProps = {
      ...slice,
      key: id,
      id,
      global: props.global,
    }
    switch (slice.slice_type) {
      case "about":
        return <About {...componentProps} />
      case "faq":
        return <FAQ {...componentProps} />
      case "hero":
        return <Hero {...componentProps} />
      case "locations":
        return <Locations {...componentProps} />
      case "new_info":
        return <NewInfo {...componentProps} />
      case "permission":
        return <Permission {...componentProps} />
      case "stats":
        return <Stats {...componentProps} />
      case "tarja":
        return <Tarja {...componentProps} />
      default:
        return
    }
  }

  return <div className="slices">{props.slices.map(sliceRender)}</div>
}

SliceRenderer.propTypes = {
  page: PropTypes.string.isRequired,
  slices: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SliceRenderer
