import React from "react"
import { useTranslation } from "react-i18next"
import BiobankChooser from "./BiobankChooser"

const Hero = ({ id, primary, global }) => {
  const { t } = useTranslation()
  return (
    <div className="hero container" id={id}>
      <div className="primary">
        <h1>{primary.heading}</h1>
        <ul className="ctas">
          <li>
            <a className="btn ghost" href="#homepage-about">
              {t("read_more")}
            </a>
          </li>
        </ul>
      </div>
      <BiobankChooser
        onVisibilityChange={isVisible => {
          document.body.classList.toggle(
            "show-fixed-biobank-chooser",
            !isVisible
          )
        }}
      />
      <div className="total-consents">
        <p
          className="total"
          dangerouslySetInnerHTML={{
            __html: t("total_consents", { total: primary.total_permissions }),
          }}
        ></p>
        <p className="date">
          {t("consent_updated_on", { date: global.statistics_last_updated })}
        </p>
        <p className="consent">
          <a className="btn" href="#homepage-stats">
            {t("consent_for_statistics")}
          </a>
        </p>
      </div>
    </div>
  )
}

export default Hero
