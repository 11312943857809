import React from "react"
import PropTypes from "prop-types"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

const FAQItem = (item, idx) => (
  <AccordionItem key={idx}>
    <AccordionItemHeading>
      <AccordionItemButton>
        <h3>{item.title}</h3>
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <div dangerouslySetInnerHTML={{ __html: item.content.html }} />
    </AccordionItemPanel>
  </AccordionItem>
)

const FAQ = ({ id, items, primary }) => {
  return (
    <div className="faq slice" id={id}>
      <div className="container-small">
        <h2>{primary.heading}</h2>
        <Accordion allowZeroExpanded={true}>{items.map(FAQItem)}</Accordion>
      </div>
    </div>
  )
}

FAQ.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
}

export default FAQ
