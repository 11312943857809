var PrismicDOM = require("prismic-dom")
var Elements = PrismicDOM.RichText.Elements

var linkResolver = function(doc) {
  return "/doc/" + doc.id
}

const htmlSerializer = (type, element, content ) => {
  switch (type) {
    // Add a class to hyperlinks
    case Elements.hyperlink: {
      const target = element.data.target
        ? 'target="' + element.data.target + '" rel="noopener"'
        : ""
      const linkUrl = PrismicDOM.Link.url(element.data)

      let finalUrl = linkUrl
      if (linkUrl.match(/https:\/\/#/)) {
        const tokens = linkUrl.split("#")
        finalUrl = `#${tokens[1]}`
      }

      return (
        '<a class="some-link"' +
        target +
        ' href="' +
        finalUrl +
        '">' +
        content +
        "</a>"
      )
    }

    // Return null to stick with the default behavior for all other elements
    default:
      return null
  }
}

export const fixAnchors = html =>
  PrismicDOM.RichText.asHtml(html, linkResolver, htmlSerializer)
