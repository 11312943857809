import React from "react"
import PropTypes from "prop-types"
import { fixAnchors } from "../helper/anchors"

const About = ({ id, primary }) => {
  const fixed = fixAnchors(primary.content.raw)
  return (
    <div className="about container-small slice" id={id}>
      <h2>{primary.heading}</h2>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: fixed }}
      ></div>
    </div>
  )
}

About.propTypes = {
  id: PropTypes.string.isRequired,
  primary: PropTypes.object.isRequired,
}

export default About
