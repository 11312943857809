import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { HorizontalBar } from "react-chartjs-2"

const transformPrismicData = (title, data) => {
  return {
    labels: data.map((point) => point.x),
    datasets: [
      {
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(25,129,219,1)",
        borderColor: "#1881db",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        fillColor: "red",
        data: data.map((point) => parseInt(point.y, 10)),
      },
    ],
  }
}

const StatItem = (stat, idx) => {
  const { t } = useTranslation()

  const statClasses = classNames({
    stat: true,
    primary: stat.primary,
  })
  return (
    <div className={statClasses} key={idx}>
      <p className="total">{stat.total_consents}</p>
      <p className="change">
        {t("percentage_of_total", { total: stat.percentage_of_total })}
      </p>
      <h4>{stat.biobank}</h4>
    </div>
  )
}

const Stats = ({ id, primary, items, global }) => {
  const { t } = useTranslation()

  let graph = null
  let primaryStats
  if (!global.show_graph) {
    primaryStats = items.filter((item) => item.primary)
  } else {
    graph = (
      <>
        <h3>{t("graph_title")}</h3>
        <HorizontalBar
          data={transformPrismicData(t("graph_title"), global.graph_data)}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </>
    )
  }

  const secondaryItems = items.filter((item) => !item.primary)

  return (
    <div className="stats slice" id={id}>
      <div className="container-small">
        <h2>{primary.heading}</h2>
        {primaryStats && (
          <div className="primary-stats">{primaryStats.map(StatItem)}</div>
        )}
        {graph && <div className="graph">{graph}</div>}
        <div className="local-stats">{secondaryItems.map(StatItem)}</div>
        <p className="post-stats">
          <span>{t("consents_only_from_adults")}</span>
          <span>
            {t("consent_updated_on", { date: global.statistics_last_updated })}
          </span>
        </p>
      </div>
    </div>
  )
}

Stats.propTypes = {
  id: PropTypes.string.isRequired,
  primary: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Stats
