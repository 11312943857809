import React, { useEffect } from "react"
import { graphql } from "gatsby"

import SliceRenderer from "../components/SliceRenderer"
import SEO from "../components/SEO"

const Index = ({ data }) => {
  useEffect(() => {})

  const content = data.homepage.data
  const slices = data.homepage.data.body

  const sliceProps = {
    page_title: data.homepage.data.page_title,
    page_description: data.homepage.data.page_description,
    statistics_last_updated: data.homepage.data.statistics_last_updated,
    show_graph: data.homepage.data.show_graph,
    graph_data: data.homepage.data.graph_data,
  }
  return (
    <>
      <SEO
        title={content.page_title.text}
        description={content.page_description}
        image={content.share_image.url}
      />
      <SliceRenderer page="homepage" slices={slices} global={sliceProps} />
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery($prismicLocale: String!) {
    homepage: prismicHomepage(lang: { eq: $prismicLocale }) {
      data {
        page_title {
          text
        }
        page_description
        share_image {
          url
        }
        statistics_last_updated
        show_graph
        graph_data {
          x
          y
        }
        body {
          ... on PrismicHomepageDataBodyHero {
            primary {
              total_permissions
              heading
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyAbout {
            primary {
              heading
              content {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyTarja {
            primary {
              content {
                html
                text
              }
              image {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyPermission {
            primary {
              heading
            }
            items {
              icon {
                url
              }
              step_title
              step_description {
                html
                text
                raw
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyLocations {
            primary {
              heading
              content {
                html
              }
              map {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyStats {
            primary {
              heading
            }
            items {
              primary
              biobank
              total_consents
              percentage_of_total
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyNewInfo {
            primary {
              heading
            }
            items {
              title
              description {
                html
              }
              link {
                target
                url
              }
              image {
                url
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyFaq {
            primary {
              heading
            }
            items {
              content {
                html
              }
              title
            }
            slice_type
          }
        }
      }
    }
  }
`
