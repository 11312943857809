import React from "react"
import PropTypes from "prop-types"
import { fixAnchors } from "../helper/anchors"

const PermissionStep = (step, idx) => (
  <div className="step" key={idx}>
    <div className="icon">
      <img src={step.icon.url} alt="" />
    </div>
    <div className="content">
      <h3>{step.step_title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: fixAnchors(step.step_description.raw),
        }}
      ></div>
    </div>
  </div>
)

const Permission = ({ id, primary, items }) => {
  return (
    <div className="permission slice" id={id}>
      <div className="container-small">
        <h2>{primary.heading}</h2>
        <div className="steps">{items.map(PermissionStep)}</div>
      </div>
    </div>
  )
}

Permission.propTypes = {
  id: PropTypes.string.isRequired,
  primary: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Permission
