import React from "react"
import PropTypes from "prop-types"

const Tarja = ({ id, primary }) => {
  return (
    <div className="tarja container-small slice" id={id}>
      {/* <h2>{primary.heading}</h2> */}
      <div className="content-holder">
        <img className="img" src={primary.image.url} />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: primary.content.html }}
        ></div>
      </div>
    </div>
  )
}

Tarja.propTypes = {
  id: PropTypes.string.isRequired,
  primary: PropTypes.object.isRequired,
}

export default Tarja
