import React from "react"
import PropTypes from "prop-types"

const Locations = ({ id, primary }) => {
  return (
    <div className="locations container-small slice" id={id}>
      <h2>{primary.heading}</h2>
      <img className="map" src={primary.map.url} alt="" />
      <div dangerouslySetInnerHTML={{ __html: primary.content.html }}></div>
    </div>
  )
}

Locations.propTypes = {
  id: PropTypes.string.isRequired,
  primary: PropTypes.object,
}

export default Locations
